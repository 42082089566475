<template>
  <div v-if="$Can('view_schedule_tab')">
    <div class="container mb-50 text-center d-flex justify-content-center align-items-center">
      <div class="mr-3 w-25">
        <flat-pickr v-model="selectedDates" class="form-control w-100" :config="{ mode: 'range', locale: { firstDayOfWeek: 1 }, onChange: onDateChange }" />
      </div>
      <div class="d-flex justify-content-left">
        <b-button class="mr-1" variant="info" @click="selectThisWeek">This Week</b-button>
        <b-button class="mr-1" variant="info" @click="selectThisMonth">This Month</b-button>
        <b-button class="mr-1" variant="info" @click="selectLastMonth">Last Month</b-button>
        <b-button class="mr-1" variant="info" @click="selectNextMonth">Next Month</b-button>
        <b-button class="mr-1" variant="info" @click="selectThisYear">This Year</b-button>
      </div>
    </div>

    <b-card title="User Weekly Task Report">
      <b-row class="text-center">
        <b-col md="12" class="text-center">
          <b-card style="text-align: center;">
            <h4 class="mb-1">User Tasks - Weekly</h4>
            <canvas id="userWeeklyTasksBarChart" width="400" height="200"></canvas>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
  <div v-else class="text-danger text-center mt-5">
    No Permission
  </div>
</template>

<script>
import userStoreModule from '../userStoreModule';
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import Chart from 'chart.js';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import router from '@/router';

export default {
  setup() {
    const USER_REPORT_APP_STORE_MODULE_NAME = 'user-report';
    if (!store.hasModule(USER_REPORT_APP_STORE_MODULE_NAME)) store.registerModule(USER_REPORT_APP_STORE_MODULE_NAME, userStoreModule);
    onUnmounted(() => {
      if (store.hasModule(USER_REPORT_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_REPORT_APP_STORE_MODULE_NAME);
    });
  },

  components: {
    flatPickr,
    Treeselect,
  },

  data() {
    return {
      selectedDates: [],
      userTasks: [],
      selectedUser: [parseInt(router.currentRoute.params.id)],
      chartInstance: null,
      datePickerConfig: {
        dateFormat: 'd/m/Y',
        mode: 'range',
        locale: {
          firstDayOfWeek: 1, // Monday
        },
        onChange: this.onDateChange,
      },
    };
  },

  created() {
    if (this.$Can('view_schedule_tab')) this.setDefaultDates();
  },

  methods: {
    setDefaultDates() {
      const now = new Date();
      const firstDayOfWeek = now.getDate() - now.getDay() + 1; // Monday
      const lastDayOfWeek = firstDayOfWeek + 6; // Sunday
      const firstDay = new Date(now.setDate(firstDayOfWeek));
      const lastDay = new Date(now.setDate(lastDayOfWeek));
      this.selectedDates = [firstDay, lastDay];

      this.fetchUserReport();
    },

    onDateChange(selectedDates) {
      if (JSON.stringify(this.selectedDates) !== JSON.stringify(selectedDates)) {
        this.selectedDates = selectedDates;
        this.fetchUserReport();
      }
    },

    fetchUserReport() {
      if (this.selectedDates.length !== 2) return;

      let [startDate, endDate] = this.selectedDates;
      startDate = new Date(startDate).toISOString().split('T')[0];
      endDate = new Date(endDate).toISOString().split('T')[0];

      store
        .dispatch('user-report/userReport', {
          startDate,
          endDate,
          userIds: this.selectedUser,
        })
        .then((response) => {
          this.userTasks = response.data.data;
          this.taskDistributionByPriority = response.data.taskDistributionByPriority;
          this.renderCharts();
        })
        .catch((error) => {
          console.error('Error fetching user report:', error);
        });
    },

    renderCharts() {
      this.renderUserWeeklyTasksBarChart();
    },

    renderUserWeeklyTasksBarChart() {
      if (this.chartInstance) {
        this.chartInstance.destroy();
      }

      const ctx = document.getElementById('userWeeklyTasksBarChart').getContext('2d');
      const labels = this.userTasks.map((user) => user.user_name);
      const datasets = [];

      this.userTasks.forEach((user) => {
        user.tasks.forEach((task) => {
          let dataset = datasets.find((d) => d.label === task.task_title);
          if (!dataset) {
            dataset = {
              label: task.task_title,
              data: Array(this.userTasks.length).fill(0),
              backgroundColor: task.color,
            };
            datasets.push(dataset);
          }
          dataset.data[this.userTasks.findIndex((u) => u.user_id === user.user_id)] = task.count;
        });
      });

      this.chartInstance = new Chart(ctx, {
        type: 'bar',
        data: {
          labels,
          datasets,
        },
        options: {
          responsive: true,
          scales: {
            xAxes: [{ stacked: true }],
            yAxes: [{ stacked: true, ticks: { beginAtZero: true } }],
          },
        },
      });
    },

    selectThisWeek() {
      const now = new Date();
      const firstDayOfWeek = now.getDate() - now.getDay() + 1; // Monday
      const lastDayOfWeek = firstDayOfWeek + 6; // Sunday
      const dates = [new Date(now.setDate(firstDayOfWeek)), new Date(now.setDate(lastDayOfWeek))];
      this.selectedDates = dates;
      this.fetchUserReport();
    },

    selectThisMonth() {
      const now = new Date();
      const dates = [new Date(now.getFullYear(), now.getMonth(), 1), new Date(now.getFullYear(), now.getMonth() + 1, 0)];
      this.selectedDates = dates;
      this.fetchUserReport();
    },

    selectLastMonth() {
      const now = new Date();
      const dates = [new Date(now.getFullYear(), now.getMonth() - 1, 1), new Date(now.getFullYear(), now.getMonth(), 0)];
      this.selectedDates = dates;
      this.fetchUserReport();
    },

    selectNextMonth() {
      const now = new Date();
      const dates = [new Date(now.getFullYear(), now.getMonth() + 1, 1), new Date(now.getFullYear(), now.getMonth() + 2, 0)];
      this.selectedDates = dates;
      this.fetchUserReport();
    },

    selectThisYear() {
      const now = new Date();
      const dates = [new Date(now.getFullYear(), 0, 1), new Date(now.getFullYear(), 11, 31)];
      this.selectedDates = dates;
      this.fetchUserReport();
    },
  },
};
</script>

<style scoped>
canvas {
  max-width: 100%;
}

.total-tasks-display h2 {
  font-size: 4em;
  color: #42a5f5;
  text-align: center;
}

.flat-pickr-wrapper {
  display: flex;
  align-items: center;
}

.flat-pickr-wrapper .form-control {
  margin-right: 1rem;
}

.flat-pickr-wrapper .d-flex {
  flex-wrap: wrap;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
